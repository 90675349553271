@import "../../../Utils/styles.css";

.ChatItem {
    width: 95%;
    overflow-y: visible;
    font-family: 'Roboto';
}

.OverallDate {
    margin-top: 30px;
    border: 4px solid #FFFFFF20;
    border-left: none;
    border-right: none;
    padding: 10px 5px;
    padding-top: 15px;
    font-weight: bold;
}

.Receiver { color: var(--alt-color); }

.ProfileContainer {
    font-weight: 750;
    font-size: 16px;
    background-color: transparent;
    padding-bottom: 5px;
    margin-top: 20px;
    display: flex;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    height: min-content;
    align-items: center;
}

.ProfileContainer.Sender {
    flex-direction: row-reverse;
}

.ProfileDate {
    font-size: 12px;
    margin: 0 10px;
    opacity: .5;
}

.MessageContainer {
    max-width: 80%;
    padding: 8px 10px;
    float: left;
    position: relative;
    border: 1px solid transparent;
    margin-bottom: -1px;
    display: flex;
    justify-content: flex-start;

    transition: border-color 0.2s linear, background-color 0.2s linear;
}

.MessageContainer.Sender {
    float: right;
    border-right-color: var(--main-color);
    border-radius: 20px 0 0 20px;
    justify-content: flex-end;
}

.MessageContainer.Receiver {
    border-left-color: var(--alt-color);
    border-radius: 0 20px 20px 0;
}

.MessageContainer:hover {
    background-color: #FFFFFF20;
    cursor: pointer;
}

.MessageText {
    color: var(--text-color);
    text-align: left;
    line-height: 24px;
    font-size: 22px;
    max-width: 100%;
    word-wrap: break-word;

    transition: color 0.2s linear;
}

.ImageContainer {
    display: flex;
    max-width: 60%;
    position: relative;
}

.MessageImage {
    max-width: 100%;
    border-radius: 15px;
    background-color: black;
    object-fit: scale-down;
}

.ImageOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #00000080;
    z-index: 1;
    top: 0;
    left: 0;
    border-radius: 15px;
    opacity: 0;
    cursor: pointer;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    transition: opacity 0.2s linear;
}

.ImageContainer:hover .ImageOverlay { opacity: 1; }

.CloseImage {
    position: absolute;
    top: 10px;
    right: 10px;
    color: var(--alt-color);
    background-color: var(--main-bg);
    padding: 5px 20px;
    border-radius: 30px;
    border: 2px solid var(--alt-color);
    visibility: collapse;
    font-size: 24px;
    cursor: pointer;
}

.Sender .MessageText {
    text-align: right;
}

.MessageDate {
    position: absolute;
    font-size: 12px !important;
    opacity: 0;
    width: max-content;
    top: 50%;
    transform: translateY(-50%);
    
    transition: opacity 0.2s linear;
}

.Sender .MessageDate { right: calc(100% + 10px); }
.Receiver .MessageDate { left: calc(100% + 10px); }

.MessageContainer.Sender:hover { border-color: var(--main-color); }
.MessageContainer.Receiver:hover { border-color: var(--alt-color); }

.MessageContainer.Sender:hover .MessageText { color: var(--main-color); }
.MessageContainer.Receiver:hover .MessageText { color: var(--alt-color); }

.MessageContainer.Sender:hover .MessageDate, .MessageContainer.Receiver:hover .MessageDate {
    opacity: 0.6;
}

.Fullscreen {
    position: fixed;
    max-width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    background-color: #00000090;
}

.Fullscreen img { width: 100vw; height: 100vh; }

.Collapsed { visibility: collapse; }

.Visible { visibility: visible; }

.Blink {
    animation-name: blink;
    animation-duration: 1s;
    animation-iteration-count: 3;
}

@keyframes blink {
    0% {
        background-color: transparent;
    }
    50% {
        background-color: white;
    }
    100% {
        background-color: transparent;
    }
}

@media (max-width: 600px) {
    
    .ProfileContainer {
        font-size: 10px;
        margin-top: 16px;
    }
    
    .MessageContainer {
        flex-direction: column;
        padding: 6px 10px;
    }

    .MessageContainer.Sender {
        max-width: 90%;
        align-items: flex-end;
    }

    .MessageText {
        font-size: 16px;
        line-height: 22px;
    }

    .Sender .MessageDate, .Receiver .MessageDate {
        position: static;
        max-height: 0px;
        margin: 10px 5px -10px 5px;
        overflow: hidden;

        transition: max-height 0.3s linear;
    }

    .Sender .MessageDate {
        margin-left: 10px;
        align-self: flex-start;
    }

    .Receiver .MessageDate {
        text-align: right;
        max-width: 95%;
        width: 95%;
    }

    .MessageContainer:hover .MessageDate {
        max-height: 20px;
    }

    .ImageContainer { max-width: 100%; }

    .ImageContainer:hover .ImageOverlay { opacity: 0; }

}