.Container {
    max-width: calc(100% - 30px);
    margin: 0 15px;
}

.SearchItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;
    max-width: 100%;
    height: min-content;
    border-left: 2px solid var(--text-color);
    padding-left: 10px;
    text-align: left;

    transition: background-color 0.3s linear;
}

.SearchItem:hover {
    background-color: #FFFFFF30;
    cursor: pointer;
}

.ResultSender {
    font-size: 16px;
    font-weight: bold;
}

.ResultText {
    color: var(--text-color);
    word-wrap: break-word;
    word-break: break-all;
}

.Content {
    
}

.TimeStamp {
    font-size: 16px;
    margin-left: 10px;
    min-width: 60px;
    text-align: right;
}

.DateHeader {
    margin-top: 15px;
    margin-bottom: 10px;
}

@media (max-width: 600px) {

    .ResultSender, .TimeStamp { font-size: 14px; }

    .SearchItem {
        font-size: 16px;
    }

}