@import "../../../Utils/styles.css";

.NicknameWindow {
    position: absolute;
    align-self: center;
    justify-self: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 30%;
    width: 40%;
    max-width: 750px;
    padding-bottom: 20px;
    background-color: var(--main-bg-20);
    border-radius: 10px;
    box-shadow: 2px 2px 10px black,
                -2px -2px 10px black;
}

.NicknameWindow span {
    color: var(--text-color);
}

.CloseNickname {
    color: var(--alt-color-danger);
    border: 2px solid var(--alt-color-danger);
    border-radius: 15px;
    width: min-content;
    height: min-content;
    padding: 5px 10px;
    position: absolute;
    right: -50px;
    top: -5px;
    font-weight: bold;

    transition: color 0.1s linear,
                background-color 0.1s linear;
}

.CloseNickname:hover {
    cursor: pointer;
    color: var(--text-color);
    background-color: var(--alt-color-danger);
}

.InputContainer {
    width: 80%;
    margin: 0 10px;
}

.Input {
    width: 100%;
    height: min-content;
    margin: 5px 0 0 0;
    font-size: 20px;
    text-align: center;
    background-color: transparent;
    border-color: transparent;
    border-bottom: 2px solid var(--main-bg);
    color: var(--main-bg-90);
}

.Submit {
    width: 70%;
}

@media (max-width: 600px) {

    .NicknameWindow {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
    }

    .CloseNickname {
        border-radius: 20px;
        padding: 4px 12px;
        top: -20px;
        right: -25px;
    }

}