@import "../../../Utils/styles.css";

.UploadPhotoWindow {
    position: absolute;
    align-self: center;
    justify-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90%;
    width: 90%;
    padding-bottom: 20px;
    background-color: var(--main-bg);
    border-radius: 10px;
    box-shadow: 2px 2px 10px black,
                -2px -2px 10px black;
}

.Form {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.ImageContainer {
    max-width: 98%;
    max-height: 80%;
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.Image {
    max-width: 98%;
    max-height: 98%;
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 2px 2px 5px #FFFFFF80,
                -2px -2px 5px #FFFFFF80;
}

.AddedTags {
    display: flex;
    gap: 2.5px;
    flex-wrap: wrap;
}

.AddedTag {
    color: var(--main-color);
    background-color: #000000A0;
    padding: 2px 6px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 7.5px;
    justify-content: space-between;
}

.Tags {
    margin: 5px 10px;
    color: var(--text-color);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2.5px;
    flex-wrap: wrap;
}

.Tag {
    color: var(--alt-color);
    background-color: #000000A0;
    padding: 2px 6px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 7.5px;
    justify-content: space-between;
    cursor: pointer;
}

.RemoveTag {
    color: #FF4040;
    transform: rotate(45deg);
    margin-top: 5px;
    cursor: pointer;
}

.TagsTB {
    border: none;
    border: 2px solid transparent;
    color: var(--text-color);
    background-color: transparent;
    min-width: 20px;
    max-width: 100px;
    text-align: center;
}

.TagsTB:hover, .TagsTB:focus { border-bottom: 2px solid var(--text-color); }

.Chooser {
    position: absolute;
    bottom: 20px;
    left: 20px;
}

.CloseSearch {
    all: unset;
    color: var(--alt-color-danger);
    border: 2px solid var(--alt-color-danger);
    border-radius: 15px;
    width: min-content;
    height: min-content;
    padding: 5px 12px;
    position: absolute;
    right: -10px;
    top: -10px;
    font-weight: bold;
    background-color: var(--main-bg);
    z-index: 10;

    transition: color 0.1s linear,
                background-color 0.1s linear;
}

.CloseSearch:hover {
    cursor: pointer;
    color: var(--text-color);
    background-color: var(--alt-color-danger);
}

.Buttons {
    position: absolute;
    bottom: 10px;
    right: 20px;
    display: flex;
    justify-content: space-evenly;
}

.Upload, .Cancel {
    all: unset;
    width: 30%;
    min-width: 75px;
    border-radius: 20px;
    padding: 10px 0;
    color: var(--main-bg);
    box-shadow: 2px 2px 5px black;
    font-weight: bold;

    transition: color 0.1s linear,
                background-color 0.1s linear;
}

.Upload { background-color: #5AA1C2E0; }
.Cancel { background-color: var(--main-bg-70); }

.Loading {
    margin-right: 20px;
    margin-bottom: 20px;
    position: relative;
}

.Loading span {
    position: absolute;
    bottom: 0;
    right: 50%;
    transform-origin: bottom center;
}

.Loading span:first-child { animation: spin 2s infinite linear; }
.Loading span:last-child { rotate: 180deg; animation: spin 2s infinite linear; }

.Icon {
    text-align: center;
    vertical-align: sub;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

@media (max-width: 600px) {

    .UploadPhotoWindow {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 95%;
        justify-content: flex-start;
    }

    .CloseSearch {
        padding: 3px 12px;
        border-radius: 20px;
    }

    .Upload {
        font-size: 16px;
        min-width: 50px;
    }

}