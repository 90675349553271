.ImagesViewer {
    display: flex;
    position: relative;
    height: 100%;
    max-height: 100%;
    width: 100%;
    max-width: 100%;
    background-color: var(--main-bg);
}

.ImageSlider {
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    transition: .5s ease-in-out;
    scroll-snap-type: x mandatory;
    overflow-x: auto;
    scrollbar-width: none;
}

.ImageSlider > img { 
    height: 100%;
    width: 100vw;
    flex-shrink: 0;
    object-fit: scale-down;
    scroll-snap-align: start;
}

.Container {
    width: 100%;
    height: 100%;
    display: flex;
}

.IV_Header, .IV_Footer, .IV_Left, .IV_Right {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    max-height: 200px;
    min-height: 0;
    background-color: black;
    overflow: hidden;
    padding: 0;
    z-index: 11;

    transition: max-height 0.3s linear;
}

.IV_Header { top: 0; }

.IV_Footer { bottom: 0; }

.IV_Header > .Container { flex-direction: row-reverse; }
.IV_Footer > .Container {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px 0;
}

.IV_Left, .IV_Right {
    border: none;
    border-radius: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: fit-content;
    background-color: transparent;
    font-size: 48px;
    color: white;
    box-shadow: 2px 2px 2px 2px black;
    cursor: pointer;
    transform: scale(1.2, 2);
    padding: 0;

    transition: color 0.2s linear,
                background-color 0.2s linear,
                box-shadow 0.3s linear,
                max-height 0.3s linear;
}

.IV_Left > .Container, .IV_Right > .Container {
    padding: 7.5px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.IV_Left { left: 24px; }
.IV_Right { right: 24px; }

.IV_Left:hover, .IV_Right:hover { color: var(--main-color); background-color: var(--main-bg); }

.IV_Left:disabled, .IV_Right:disabled { opacity: .2; pointer-events: none; }

.OverlaysHiding { max-height: 0; box-shadow: none; }

.AddedTags {
    display: flex;
    gap: 2.5px;
    flex-wrap: wrap;
}

.AddedTag {
    color: var(--main-color);
    background-color: #000000A0;
    padding: 2px 6px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 7.5px;
    justify-content: space-between;
}

.Tags {
    margin: 5px 10px;
    color: var(--text-color);
    display: flex;
    align-items: center;
    gap: 2.5px;
    overflow-x: auto;
    max-width: 95%;
}

.Tag {
    color: var(--alt-color);
    background-color: #000000A0;
    padding: 2px 6px;
    border-radius: 10px;
    cursor: pointer;
    display: block;
    min-width: max-content;
    width: max-content;

    transition: background-color 0.2s linear, color 0.2s linear;
}

.Tag:hover { background-color: var(--main-bg-30); }

.RemoveTag {
    color: #FF4040;
    transform: rotate(45deg);
    margin-top: 5px;
    cursor: pointer;
}

.TagsTB {
    border: none;
    border: 2px solid transparent;
    color: var(--text-color);
    background-color: transparent;
    min-width: 20px;
    max-width: 100px;
    text-align: center;
}

.SaveTagChangesBtn {
    all: unset;
    margin-left: 50px;
    color: #5AA1C2E0;
    border: 2px solid #5AA1C2E0;
    padding: 2px 5px;
    border-radius: 10px;
    
    transition: color 0.2s linear, background-color 0.2s linear;
}

.SaveTagChangesBtn:hover {
    background-color: transparent;
}

.CloseIV {
    color: var(--alt-color);
    background-color: transparent;
    padding: 20px 30px;
    border-radius: 30px;
    font-size: 24px;
    font-weight: 800;
    cursor: pointer;
}

@media (max-width: 600px) {

    .IV_Left, .IV_Right {
        display: none;
    }

}