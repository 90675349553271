@import "../../../Utils/styles.css";

.ColorPallete {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--main-bg);
}

.MainColor {
    color: var(--main-color);
}

.MainHoverColor {
    color: var(--main-hover-color);
}

.MainClickColor {
    color: var(--main-click-color);
}

.AltColor {
    color: var(--alt-color);
}

.AltHoverColor {
    color: var(--alt-hover-color);
}

.AltClickColor {
    color: var(--alt-click-color);
}

.WhiteColor {
    color: white;
}