/* @import "../../../Utils/styles.css"; */

.ChatHeader {
    display: inline-grid;
    grid-template-columns: auto 1fr auto;
    justify-content: space-between;
    align-items: center;
    color: var(--alt-color);
    width: 100%;
    height: max-content;
    font-size: 20px;
}

.HeaderText {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--main-color);
    font-size: 28px;
    margin-bottom: 5px;
}

.StatusText {
    display: flex;
    flex-direction: column;
    font-size: 14px;
}

.Online { color: lightgreen; }
.Offline { color: var(--main-bg-50); }

.MembersDropdown {
    /* grid-row: 1;
    grid-column: 1; */
}

.MemberName {
    width: max-content;
    max-width: 80%;
}

/* .MembersDropBtn {
    border: none;
    font-size: 18px;
    border-radius: 5px;
    margin-left: -5px;
    background-color: transparent;

    transition: background-color 0.1s linear, color 0.1s linear;
} */

.MembersDropContent {
    display: none;
    position: absolute;
    z-index: 1;
    max-width: 90vw;
    background-color: var(--main-bg-20);
    border: 3px solid var(--main-bg-50);
    border-radius: 5px;
    padding: 10px;
    margin: 0 10px 10px 0;
    color: var(--text-color);
}

.MembersDropItem {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    justify-content: space-between;
    text-align: left;
}

.MembersDropItem button {
    margin-left: 10px;
    font-size: 14px;
    border: none;
    width: 120px;
    padding: 0;
    color: var(--main-color);
    background-color: transparent;
    padding: 5px;
}
.MembersDropItem button:hover {
    background-color: transparent;
    color: var(--main-color);
}

.MembersDropdown:hover .MembersDropContent,
.MembersDropdown:focus .MembersDropContent,
.IconDropdown:hover .IconDropContent,
.IconDropDown:focus .IconDropContent {
    display: block;
}

select {
    background-color: var(--main-bg-70);
    border-color: var(--main-bg-70);
    border-radius: 5px;
    margin: 10px;
    padding: 2px 5px;
    color: var(--main-bg-20);
    z-index: 2;
}

input[type="text"] {
    margin: 5px;
    grid-column: 1;
    width: 95%;
}

.Icons {
    grid-column: 3;
    grid-row: 1;
    display: flex;
    align-items: center;
    gap: 10px;
}

.IconDropdown {
    position: relative;
    display: inline-block;
    grid-row: 1;
    grid-column: 1;
}

.IconDropBtn {
    border: none;
    font-size: 18px;
    border-radius: 5px;
    margin-left: -5px;
    background-color: transparent;

    transition: background-color 0.1s linear, color 0.1s linear;
}

.IconDropContent {
    display: none;
    position: absolute;
    z-index: 1;
    background-color: var(--main-bg-20);
    border: 3px solid var(--main-bg-50);
    border-radius: 5px;
    color: var(--text-color);
    width: 150px;
}

.IconDropDownItem {
    all: unset;
    padding: 10px 0px;
    font-size: 16px;
    border-bottom: 2px solid var(--main-bg-50);
    width: 100%;
}

.IconFont {
    font-size: 12px;
}

button {
    box-shadow: none;
    border: 0;
    border-style: solid;
    border-width: 2px;
    margin: 5px;
    z-index: 2;
    grid-row: 1;
    min-width: 50px;
    font-size: 16px;

    background-color: var(--main-bg);
    color: var(--alt-color-dark);
    border-color: var(--alt-color-dark);
    border-radius: 20px;
    transition: background-color 0.2s linear, color 0.2s linear, border-color 0.2s linear;
}

button:hover {
    background-color: var(--alt-color-dark);
    color: var(--text-color);
    cursor: pointer;
}

@media (max-width: 600px) {

    .HeaderText {
        width: 100%;
        font-size: 18px;
    }

    select, button {
        font-size: 18px;
    }

    .DropBtn {
        font-size: 14px;
        width: min-content;
    }

    button {
        font-size: 12px;
    }

}