:root {
    --main-bg: hsl(200, 6%, 10%);
    --main-bg-15: hsl(200, 6%, 15%);
    --main-bg-20: hsl(200, 6%, 20%);
    --main-bg-30: hsl(200, 6%, 30%);
    --main-bg-50: hsl(200, 6%, 50%);
    --main-bg-70: hsl(200, 6%, 70%);
    --main-bg-90: hsl(200, 5%, 90%);

    --main-color: hsl(260, 97%, 80%);
    --main-color-dark: hsl(260, 97%, 60%);
    --main-hover-color: hsl(271, 90%, 85%);
    --main-click-color: hsl(271, 90%, 90%);

    --alt-color: hsl(349, 100%, 72%);
    --alt-color-dark: hsl(349, 50%, 60%);
    --alt-color-danger: hsl(349, 75%, 60%);
    --alt-hover-color: hsl(359, 100%, 80%);
    --alt-click-color: hsl(359, 100%, 90%);
    --text-color: hsl(0, 0%, 85%);
}

input[type="text"] {
    transition: border-bottom-color 0.2s linear;
}

input[type="text"]:hover {
    border-bottom-color: var(--main-bg-50);
}

input[type="text"]:focus {
    outline: none;
    border-bottom-color: var(--main-bg-50);
}

button {
    height: min-content;
    padding: 7.5px 12px;
}

hr {
    width: 95%;
    color: var(--text-color);
}

a:visited {
    color:hsl(260, 97%, 60%);
}