@import "../../../Utils/styles.css";

.ChatBar {
    display: flex;
    align-items: center;
    background-color: var(--main-bg);
    width: 100%;
    padding: 10px 0;
}

.InputContainer {
    background-color: var(--main-bg-20);
    display: flex;
    flex-grow: 1;
    padding: 5px 0;
    border-radius: 5px;
}

.InputTA {
    background-color: transparent;
    color: var(--main-bg-90);
    resize: none;
    min-height: 1rem;
    max-height: 100px;
    border: 2px solid transparent;
    margin: 5px 8px;
    flex-grow: 2;
    outline: none;
    font-family: "Roboto";
    
    transition: border-bottom 0.3s linear;
}

.InputTA.AddDropUp, .InputTA:focus {
    border-bottom-color: var(--main-bg-50);
}

.AddDropUp {
    all: unset;
    position: relative;
    margin-right: 10px;
    padding: 6px 16px;
    border-radius: 50%;
    color: #FFFFFFC0;
    font-size: 26px;
    font-weight: bold;
    background-color: #5AA1C2E0;
    cursor: pointer;
}

.AddContent {
    display: none;
    position: absolute;
    bottom: 50px;
    left: 0;
    width: max-content;
    background-color: #5AA1C2E0;
    padding: 10px;
    border-radius: 20px;
    font-size: 20px;
    cursor: default;
}

.AddDropUp:focus .AddContent { 
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.DropItem:hover div {
    color: var(--main-bg);
    cursor: pointer;
}

.AddLinkWindow {
    position: absolute;
    bottom: 75px;
    left: 0;
    background-color: var(--main-bg-20);
    border-radius: 10px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-width: 300px;
    font-size: 20px;
}

.AddLinkWindow > input {
    color: var(--text-color);
    background-color: transparent;
    border: none;
    border-bottom: 2px solid var(--main-bg-50);
    font-size: 16px;
}

.closeAddLink {
    all: unset;
    color: var(--alt-color);
    font-size: 24px;
    position: absolute;
    top: 5px;
    right: 10px;
    padding: 0px 7.5px;
    border-radius: 10px;
}

.SendButton {
    all: unset;
    border: none;
    height: min-content;
    width: fit-content;
    padding: 6px 10px;
    margin: 0;
    margin-left: 10px;
    background: var(--alt-color-dark);
    border: 2px solid var(--alt-color-dark);
    border-radius: 10px;
    color: var(--text-color);
    cursor: pointer;
    visibility: collapse;
    font-size: 18px;
}

@media (max-width: 600px) {

    .ChatBar {
        height: min-content;
    }

    .InputContainer {
        margin: 0 10px 0 0;
        height: min-content;
        padding: 0;
    }

    .InputTA {
        font-size: 14px;
        min-height: 20px;
        height: min-content;
    }

    .SendButton {
        visibility: visible;
        margin: 0;
        font-size: 14px;
        padding: 6px 10px;
    }

    .AddImage {
        font-size: 22px;
        padding: 4px 12px;
    }

}