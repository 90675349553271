@import "../../../Utils/styles.css";

.ChatWindow {
    display: grid;
    grid-template-rows: 1fr 30px;
    flex-grow: 1;
    width: 100%;
    max-width: 100vw;
    height: auto;
    background-color: var(--main-bg-15);
    border-radius: 10px;
    padding: 10px 0;
    overflow-y: hidden;
    position: relative;
}

.NewMessageOverlay {
    position: absolute;
    bottom: 10px;
    justify-self: center;
    background-color: #FFFFFF10;
    padding: 10px 20px;
    border-radius: 100px;
    cursor: pointer;

    animation: flash 5s infinite;
}

.Messages {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    overflow-y: auto;
    grid-row: 1;
    padding-bottom: 5px;
}

.Typing {
    display: flex;
    align-self: flex-end;
    justify-self: flex-start;
    grid-row: 2;
    grid-column: 1;
    margin-left: 20px;
    font-size: 14px;
    margin-bottom: -5px;
}

.MessageCount {
    grid-row: 2;
    grid-column: 1;
    justify-self: flex-end;
    align-self: flex-end;
    margin-right: 20px;
    margin-bottom: -5px;
    font-size: 14px;
    color: var(--text-color);
    opacity: 0.5;
}

.Heart {
    font-size: 12px;
    margin-left: 4px;
}

.AnimatedHeart {
    animation: spin infinite 1.5s linear;
    transform-origin: bottom center;
}

@keyframes spin {
    20% {
        transform: rotate(20deg);
    }
    60% {
        transform: rotate(-20deg);
    }
    80% {
        transform: rotate(0deg);
    }
}

@keyframes flash {
    from { background-color: #FFFFFF10; }
    50% { background-color: #FFFFFF20; }
    to { background-color: #FFFFFF10; }
}

@media (max-width: 600px) {

    .Typing {
        margin-left: 10px;
    }

    .Typing, .MessageCount, .Heart {
        font-size: 10px;
    }

}