@import "./../../../Utils/styles.css";

.Login {
    position: relative;
    color: var(--main-bg-50);
    min-height: 35%;
    min-width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background-color: var(--main-bg-20);
    border-radius: 15px;
    box-shadow: 2px 2px 20px var(--main-bg-20),
                -2px -2px 20px var(--main-bg-20);
}

.Container {
    margin: 10px;
}

.Options {
    position: inherit;
    min-height: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.Option {
    color: var(--main-color);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20%;
    width: 100%;
    padding: 10px 0;
    border: 2px solid var(--main-color);
    border-radius: 20px;

    transition: border 0.2s linear, background-color 0.2s linear;
}

.Option:hover {
    background-color: #FFFFFF20;
    cursor: pointer;
}

.ULogin {
    color: var(--main-bg-70);
    position: absolute;
    background-color: var(--main-bg);
    height: 55%;
    width: 95%;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    box-shadow: 2px 2px 20px var(--main-bg),
                -2px -2px 20px var(--main-bg);
}

.PWInput {
    height: 15%;
    width: 70%;
    border-bottom: 2px solid var(--text-color);
    padding-bottom: 10px;
}

input[type="password"] {
    font-size: 24px;
    height: 100%;
    width: 100%;
    background-color: transparent;
    border: none;
    color: var(--text-color);
    text-align: center;
    outline: none;
}

.Close {
    position: absolute;
    top: -5px;
    right: -50px;
}

.Submit {
    position: absolute;
    bottom: -45px;
    width: 80%;
}

@media (max-width: 600px) {

    .Close {
        padding: 5px 15px;
    }

    input[type="password"] {
        width: 85%
    }

}