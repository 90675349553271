@import "./Utils/styles.css";

.App {
  text-align: center;
  background-color: var(--main-bg);
  width: 100vw;
}

.Main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  color: var(--main-color);
  overflow-x: hidden;
  position: relative;
}

#Gallery {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--main-bg);
  z-index: 10;
}