@import "../../../Utils/styles.css";

.Container {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    justify-content: center;
    width: 95%;
    max-width: 1000px;
    height: 100%;
    font-family: Calibri;
    position: relative;
}

.Chat {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-self: center;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    min-height: 0;
    height: 100%;

    transition: opacity 0.2s linear;
}

.Disabled {
    opacity: .3;
    pointer-events: none;
}