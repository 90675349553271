@import "../../../Utils/styles.css";

.Container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.SearchWindow {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 80%;
    width: 90%;
    padding-bottom: 20px;
    background-color: var(--main-bg);
    border-radius: 10px;
    box-shadow: 2px 2px 10px black,
                -2px -2px 10px black;
}

.CloseSearch {
    color: var(--alt-color-danger);
    border: 2px solid var(--alt-color-danger);
    border-radius: 15px;
    width: min-content;
    height: min-content;
    padding: 5px 10px;
    position: absolute;
    right: -20px;
    top: -10px;
    font-weight: bold;

    transition: color 0.1s linear,
                background-color 0.1s linear;
}

.CloseSearch:hover {
    cursor: pointer;
    color: var(--text-color);
    background-color: var(--alt-color-danger);
}

.TextInputContainer {
    width: 80%;
    margin: 20px 0 0 5px;
}

.Input {
    width: 100%;
    height: min-content;
    margin: 5px 0 0 0;
    background-color: transparent;
    border-color: transparent;
    color: var(--main-bg-90);
    font-size: 18px;
}

.FiltersContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: 10px;
    margin-top: 5px;
    flex-wrap: wrap;
    padding: 0 16px;
}

.NonLabelFilters {
    display: flex;
    gap: 50px;
}

.FilterHeader {
    color: var(--text-color);
    font-size: 18px;
    text-decoration: underline;
}

.Filters, .ImageTags {
    display: flex;
    align-items: center;
    gap: 8px;
}

.ImageTags {
    max-width: 95%;
    overflow-x: auto;
}

.Filters label, .ImageTags label {
    cursor: pointer;
    border-radius: 10px;
    padding: 3px 8px;
    font-size: 18px;
    display: inline-block;
    width: max-content;

    transition: background-color 0.2s linear, color 0.2s linear;
}

.Filters input[type="radio"], .ImageTags input[type="checkbox"] {
    position: fixed;
    left: -9999px;
}

.Filters label:hover, .ImageTags label:hover { background-color: var(--main-bg-30); }

.Filters input[type="radio"]:checked + label,
.ImageTags input[type="checkbox"]:checked + label {
    background-color: var(--main-bg-30);
    font-weight: bold;
    color: var(--alt-color-dark);
}

.TextResults {
    max-height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: auto;
    font-size: 20px;
}

.ImageResults {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
    max-height: 100%;
    overflow-y: auto;
}

.ImageResult {
    max-width: calc(30% - 10px);
    position: relative;
}

.ImageResult > img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    border-radius: 15px;
    object-fit: scale-down;
}

.ImageOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #00000080;
    z-index: 1;
    top: 0;
    border-radius: 15px;
    opacity: 0;
    cursor: pointer;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    transition: opacity 0.2s linear;
}

.ImageResult:hover .ImageOverlay { opacity: 1; }

.CloseImage {
    position: absolute;
    top: 10px;
    right: 10px;
    color: var(--alt-color);
    background-color: var(--main-bg);
    padding: 5px 20px;
    border-radius: 30px;
    border: 2px solid var(--alt-color);
    visibility: collapse;
    font-size: 24px;
    cursor: pointer;
}

.Fullscreen {
    position: fixed;
    max-width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    background-color: #00000090;
}

.Fullscreen img { width: 100vw; height: 100vh; }

.Collapsed { visibility: collapse; }

.Visible { visibility: visible; }

@media (max-width: 600px) {

    .SearchWindow { width: 95%; }

    .NonLabelFilters {
        flex-direction: column;
        gap: 10px;
    }

    .FiltersContainer *, .FiltersContainer label {
        font-size: 14px;
    }

    .Filters, .ImageTags {
        gap: 0;
    }

    .ImageResult { max-width: calc(50% - 10px);}

    .CloseSearch {
        padding: 3px 12px;
        border-radius: 20px;
    }

    .Fullscreen {
        max-width: 100vw;
        max-height: 100vh;
    }

    .TextResults {
        margin: 0;
        padding: 0;
    }

}